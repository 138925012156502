export var CATEGORY_MENU_PARAMS = {
    showAllPostsCategory: {
        wixParam: 'showAllPostsCategory',
        appSettingsPath: 'style.booleans.showAllPostsCategory',
        defaultValue: true,
    },
    showPostCount: {
        wixParam: 'showPostCount',
        appSettingsPath: 'style.booleans.showPostCount',
        defaultValue: true,
    },
};
export var CATEGORY_MENU_MOBILE_PARAMS = {
    showAllPostsCategory: {
        wixParam: 'showAllPostsCategoryMobile',
        appSettingsPath: 'style.booleans.showAllPostsCategoryMobile',
        defaultValue: false,
    },
    showPostCount: {
        wixParam: 'showPostCountMobile',
        appSettingsPath: 'style.booleans.showPostCountMobile',
        defaultValue: false,
    },
    isMobileDisplaySettingsEnabled: {
        wixParam: 'category-menu-mobile-isDisplaySettingsEnabled',
        appSettingsPath: 'style.booleans.category-menu-mobile-isDisplaySettingsEnabled',
        defaultValue: false,
    },
    isMobileDesignSettingsEnabled: {
        wixParam: 'category-menu-mobile-isDesignSettingsEnabled',
        appSettingsPath: 'style.booleans.category-menu-mobile-isDesignSettingsEnabled',
        defaultValue: false,
    },
    isMobileLayoutSettingsEnabled: {
        wixParam: 'category-menu-mobile-isLayoutSettingsEnabled',
        appSettingsPath: 'style.booleans.category-menu-mobile-isLayoutSettingsEnabled',
        defaultValue: false,
    },
};
